import React from "react"
import { Redirect } from "react-router"
// import constants from "../../common/constants";
import Status from "../Base/Status"
import LoadingView from "../components/LoadingView"
import { buildProps, buildUrl, Props } from "./helpers"

// const isProdishEnv = constants.IS_PRODISH;

class Login extends React.Component<Props> {
  public static async getInitialProps(props) {
    return buildProps(props)
  }

  public buildUrl() {
    return buildUrl("login", this.props)
  }

  public componentDidMount() {
    const href = this.buildUrl()
    // NOTE qac: we allow this scenario in webview
    // if (isProdishEnv && !/pool|challenge/.test(href)) {
    //   console.error(`failed to redirect to good href: ${href}`);
    // } else {
    window.location.replace(href)
    // alert(href);
    // }
  }

  public render() {
    const href = this.buildUrl()
    if (typeof window !== "undefined") {
      // will use didmount to redirect
      return (
        <>
          <LoadingView variant="view" />
          <a rel="nofollow" href={href}>
            &nbsp;
          </a>
        </>
      )
    } else {
      return (
        <Status code={302}>
          <Redirect to={href} />
        </Status>
      )
    }
  }
}
export default Login
