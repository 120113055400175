import constants from "../../common/constants"
import { InitialPropsContext } from "../../routes.d"
import { getParam } from "../utils/url-utils"
import { emptyObject } from "../../common/misc-utils"

export interface Props {
  returnUrl?: string
  hostname: string
  originalUrl: string
  protocol: string
  masterProductId: string | null
  devSource: string | null
  productAbbrev: string | null
  isNativeApp: boolean | null
}

const usesOldSapiParams = constants.PUBLIC_USES_OLD_SAPI_PARAMS

const ensureFullUrl = (url: string, currentUrl: string) => {
  if (/https?\:\/\//.test(url)) {
    return url
  } else {
    const parts = currentUrl.split("://")
    const protocol = parts.shift()
    const hostname = parts[0].split("/")[0]
    const path = url.length && url[0] === "/" ? url : `/${url}`
    return `${protocol}://${hostname}${path}`
  }
}

// https://owl.cbsi.com/confluence/display/CBSSportsHighlander/User+-+Log+In
// https://owl.cbsi.com/jira/browse/SPSDF-2831
export const buildProps = (props: InitialPropsContext) => {
  const location = props.location || emptyObject
  const getQueryString = (key: string) => props?.req?.query[key] || getParam(key, location.search)
  // NOTE qac: hostname doesnt include port, however props.req.headers.host does (non-standard express)
  const hostname = props.req ? props.req.headers.host || props.req.hostname : constants.HOSTNAME
  const protocol = props.req ? props.req.protocol : constants.PROTOCOL
  const originalUrl = props.req ? props.req.originalUrl : `/${location.pathname}`.replace("//", "/")
  const masterProductId = getQueryString("masterProductId")
  const productAbbrev = getQueryString("productAbbrev")
  const devSource = getQueryString("devSource")
  const isNativeApp = !!getQueryString("isNativeApp")
  const returnUrl = getQueryString("returnUrl")
  return { protocol, hostname, originalUrl, masterProductId, devSource, productAbbrev, isNativeApp, returnUrl }
}

export const buildUrl = (endpoint: "login" | "registration" | "logout", preProps: Props) => {
  // NOTE qac: this is because for some reason render gets called before getInitialProps is passed to it :(
  const props = preProps.hasOwnProperty("returnUrl") ? preProps : buildProps(preProps as any)
  // console.log(`buildUrl:`)
  // console.dir(props)
  const { hostname, originalUrl, protocol, masterProductId, productAbbrev, isNativeApp, devSource } = props
  const currentUrl = typeof window !== "undefined" ? window.location.href : `${protocol}://${hostname}${originalUrl}`
  // make sure /auth/* routes are not in the return URL!
  // NOTE qac: if no return, fantasy rroot
  const returnUrl = (props.returnUrl || "/") !== "/" ? ensureFullUrl(props.returnUrl!.toString(), currentUrl).split("/auth/")[0] : constants.DOMAIN
  // new auth (see /users below) will support redirectUrl
  const redirectCbsParam = usesOldSapiParams ? (endpoint === "registration" ? "end" : "xurl") : "redirectUrl"
  const queryParams = [`${redirectCbsParam}=${encodeURIComponent(returnUrl)}`, "show_opts=1"]
  if (endpoint === "registration") {
    queryParams.push("bypass=1")
  }
  if (masterProductId) {
    queryParams.push(`masterProductId=${masterProductId}`)
  }
  if (productAbbrev) {
    queryParams.push(`product_abbrev=${productAbbrev}`)
  }
  if (devSource) {
    queryParams.push(`dev_source=${devSource}`)
  }
  if (isNativeApp) {
    queryParams.push(`c=sc`)
    queryParams.push(`apprsid=cbsicbssportsapp`)
  }
  // NOTE qac: these endpoints require /?... missing slash fails
  // they dont have the new version yets:  `/user/${endpoint}/?`.replace("//", "/");
  const pathname = `${usesOldSapiParams ? "" : "/user"}/${endpoint}/?`.replace("//", "/")
  // this domain is notb for us
  const domain = isNativeApp && false ? "mauth.cbssports.com" : constants.PUBLIC_SAPI_AUTH_DOMAIN
  return `https://${domain}${pathname}${queryParams.join("&")}`
}
